<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          智慧看护系统
        </h2>
      </b-link> -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <!-- <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
           欢迎使用 智慧看护系统! 👋
          </b-card-title> -->
          <b-card-text class="mb-2">
            请登录到您的帐户，并开始使用
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
               <b-form-group
                label="账户"
                label-for="login-account"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="login-account"
                    v-model="accountName"
                    :state="errors.length > 0 ? false:null"
                    name="login-account"
                    placeholder="admin"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">密码</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  记住密码
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                登录
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import request from '@/api/request'
import CryptoJS from "crypto-js";
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      accountName: '',
      sideImg: require('@/assets/images/pages/login.png'),

      // validation rules
      required,
      email,
      encryptKey:'bzuwLnZrHjtWQQZOiqBOqBOdlSUavvpS'
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    // this.test()
    this.status = window.localStorage.getItem("remeberAccountAndPwd")
    
    let encryptLoginInfoStr = window.localStorage.getItem("loginInfo")
    if(encryptLoginInfoStr){
      let decryptLoginInfoStr =  this.decryptData(encryptLoginInfoStr)
      if(decryptLoginInfoStr){
        let loginInfo = JSON.parse(decryptLoginInfoStr)
        this.accountName = loginInfo.accountName
        this.password = loginInfo.password
      }
    }
  },
  methods: {
    encryptData(data){
      const encryptKey = CryptoJS.enc.Utf8.parse(this.encryptKey)
      const encryptedContent = CryptoJS.AES.encrypt(data, encryptKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      })
      return encryptedContent.ciphertext.toString()
    },
    decryptData(data){
      const encryptKey = CryptoJS.enc.Utf8.parse(this.encryptKey)
      const decryptedContent = CryptoJS.AES.decrypt(
        CryptoJS.format.Hex.parse(data),
        encryptKey,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }
      )
      return CryptoJS.enc.Utf8.stringify(decryptedContent);
    },
    test(){
      // 已选择的节点
      let checkGroups = [
        {
          mark: "101",
          id: "1111",
          effective: "true"
        },
        {
          mark: "102",
          id: "2222",
          effective: "true"
        },
        {
          mark: "103.104",
          id: "3333",
          effective: "true"
        }
      ]
      let newCheckGroup =
      {
        mark: "103.104",
        id: "3333",
        effective: "true"
      }
      let condition = "ANY" // 任意 还是 全部
      for(let i=0; i<checkGroups.length;i++){

        // 新：子 (101.103)，已：父(101)。 新.indexOf(已)
        // 新：父 (101),已：子(101.103)。已.indexOf(新)

        // 当前选择的新分组 与 已选择的分组 父子关系
        let flag = "parent" // 默认当前选择的新分组是已选择的父节点
        const result = newCheckGroup.mark.length - checkGroups[i].mark.length;
        if(result > 0){
          // 子节点
          flag = "child"
        }else if(result == 0){
          // 兄弟节点 无需继续当前校验
          if(newCheckGroup.mark == checkGroups[i].mark){
            console.log("识别到重复选择")
            newCheckGroup.effective = false
          }
          continue
        }
        switch(condition){
          case "AND":
            // 1.选择全部(&&)
            switch(flag){
              case 'parent':
                // 1.1 判断当前选择的节点是否是前面选择的节点的一个父节点，当前节点失效
                if(checkGroups[i].mark.indexOf(newCheckGroup.mark) == 0){
                  // 可以匹配 证明当前选择的确实是已选择节点的子节点
                  console.log('找到子节点',checkGroups[i])
                  newCheckGroup.effective = false
                }
                break
              case 'child':
                // 1.2 判断当前选择的节点是前面选择的节点的一个子节点，则已选择的父节点无效
                if(newCheckGroup.mark.indexOf(checkGroups[i].mark) == 0){
                  // 可以匹配 证明当前选择的确实是已选择节点的子节点
                  console.log('找到父节点',checkGroups[i])
                  checkGroups[i].effective = false
                }
                break
            }
            break
          case "ANY":
            // 1.选择任意(||)
            switch(flag){
              case 'parent':
                // 1.1 判断当前选择的节点是否是前面选择的节点的一个父节点，前面的子节点失效
                if(checkGroups[i].mark.indexOf(newCheckGroup.mark) == 0){
                  // 可以匹配 证明当前选择的确实是已选择节点的父节点
                  console.log('找到子节点',checkGroups[i])
                  checkGroups[i].effective = false
                }
                break
              case 'child':
                // 1.2 判断当前选择的节点是前面选择的节点的一个子节点，当前选择的节点无效
                if(newCheckGroup.mark.indexOf(checkGroups[i].mark) == 0){
                  // 可以匹配 证明当前选择的确实是已选择节点的子节点
                  console.log('找到父节点',checkGroups[i])
                  newCheckGroup.effective = false
                }
                break
            }
            break
        }
      }
      // 避免选择同一个节点
      checkGroups.push(newCheckGroup)
      console.log('checkGroups',checkGroups)

    },
    login() {
      const that = this
      this.$refs.loginForm.validate().then(success => {
        if(this.status){
          let loginInfo = {
            accountName: this.accountName, 
            password: this.password
          }
          let loginInfoJson = JSON.stringify(loginInfo)
          const encryptStr = this.encryptData(loginInfoJson)
          window.localStorage.setItem("loginInfo",encryptStr)
        }else{
          window.localStorage.removeItem("loginInfo")
        }
        window.localStorage.setItem("remeberAccountAndPwd",this.status)
        if (success) {
          request.post('tob/user/bAdmin/login', { accountName:this.accountName, password:this.$md5(this.password),  "platform": "WEB" }).then(res => {
            if (res.data.success) {
                if(res.data.data.urlPermissionTree && res.data.data.urlPermissionTree.length > 0){
                  window.localStorage.setItem('Authorization', res.data.data.token)
                  window.localStorage.setItem('username', res.data.data.username)
                  let userData = res.data.data
                  console.log(userData)
                  localStorage.setItem('userData', userData)
                  localStorage.setItem("sysName",userData.sysName)
                  localStorage.setItem("sysIconUrl",userData.sysIconUrl)
                  that.$store.dispatch("user/setUserData", userData);
                  this.$router.replace(getHomeRouteForLoggedInUser(userData))
                  window.location.reload()
                }else{
                  this.$bvToast.toast("未分配任何界面，请联系管理员", {
                    title: `提醒`,
                    variant: 'danger',
                    solid: true
                  })
                  return
                }
            }else {
              if(res.data.code != 500){ // 重复通知
                this.$bvToast.toast(res.data.msg, {
                  title: `提醒`,
                  variant: 'danger',
                  solid: true
                })
              }
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.auth-wrapper{
  background: #fff;
}
</style>
